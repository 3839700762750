import Vue from "vue";
import VueRouter from "vue-router";
import jwt from "@/services/jwtService";
import place from "@/services/placeBusiness";
import permissions from "@/constants/permissions";
Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/login",
    name: "autentication",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/auth/business",
    name: "business",
    components: {
      default: () => import("../views/auth/Business.vue"),
      appBar: () => import("../components/AppBar.vue"),
    },
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/app/IndexPage.vue"),
    redirect: "/home",
    meta: { loginRequired: true },
  },
  {
    path: "/business",
    name: "businessMenu",
    components: {
      default: () => import("../views/app/menu/Business.vue"),
    },
  },
  {
    path: "/menu/:id",
    name: "menu",
    components: {
      default: () => import("../views/app/menu/Menu.vue"),
    },
  },
  {
    path: "/home",
    components: {
      default: () => import("../views/app/IndexPage.vue"),
      sideBar: () => import("../components/SideBar.vue"),
    },
    redirect: "/home",
    meta: { loginRequired: true },
    children: [
      {
        path: "/",
        name: "home",
        components: {
          default: () => import("../views/app/Home.vue"),
        },
      },
      // {
      //   path: "/menu/:id",
      //   name: "menu",
      //   components: {
      //     default: () => import("../views/app/menu/Menu.vue"),
      //   },
      // },
      // {
      //   path: "/restaurant/:id",
      //   name: "restaurant",
      //   components: {
      //     default: () => import("../views/app/menu/Menu.vue"),
      //   },
      // },
      // {
      //   path: "/plato/:id",
      //   name: "plato",
      //   components: {
      //     default: () => import("../views/app/menu/Menu.vue"),
      //   },
      // },

      /**
       * ==============================================================================
       *  MIX
       * ==============================================================================
       */
      {
        path: "/mix",
        name: "mix",
        components: {
          default: () => import("../views/app/mix/IndexPage.vue"),
        },
        redirect: "/mix/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/mix/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/mix/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/mix/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.mix[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },

      /**
       * ==============================================================================
       *  categories
       * ==============================================================================
       */
      {
        path: "/tag",
        name: "tag",
        components: {
          default: () => import("../views/app/category/IndexPage.vue"),
        },
        redirect: "/tag/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/category/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/category/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/category/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.tag[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      /**
       * ==============================================================================
       *  clasification
       * ==============================================================================
       */
      {
        path: "/category",
        name: "category",
        components: {
          default: () => import("../views/app/clasification/IndexPage.vue"),
        },
        redirect: "/category/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/clasification/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/clasification/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/clasification/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.category[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
/**
       * ==============================================================================
       *  cierre de caja
       * ==============================================================================
       */
      {
        path: "/closing-sales",
        name: "closing",
        components: {
          default: () => import("../views/app/closingSales/IndexPage.vue"),
        },
        redirect: "/closing-sales/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/closingSales/List.vue"),
          }
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.closingSales[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },

      /**
       * ==============================================================================
       *  mesa
       * ==============================================================================
       */
      {
        path: "/table",
        name: "table",
        components: {
          default: () => import("../views/app/table/IndexPage.vue"),
        },
        redirect: "/table/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/table/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/table/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/table/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.setting[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },

      /**
       * ==============================================================================
       *  orden
       * ==============================================================================
       */
      {
        path: "/order",
        name: "order",
        components: {
          default: () => import("../views/app/order/IndexPage.vue"),
        },
        redirect: "/order/list/open",
        children: [
          {
            path: "list/open",
            component: () => import("../views/app/order/List.vue"),
          },
          {
            path: "list/closed",
            component: () => import("../views/app/order/ClosedList.vue"),
          },
          // {
          //   path: 'new',
          //   component: () => import('../views/table/New.vue')
          // },
          // {
          //   path: ':id',
          //   component: () => import('../views/table/Update.vue')
          // }
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.order[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      /**
       * ==============================================================================
       *  pay
       * ==============================================================================
       */
      {
        path: "/pay",
        name: "pay",
        components: {
          default: () => import("../views/app/pay/IndexPage.vue"),
        },
        redirect: "/pay/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/pay/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/pay/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/pay/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.setting[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      /**
       * ==============================================================================
       *  pay
       * ==============================================================================
       */
      {
        path: "/permission",
        name: "permission",
        components: {
          default: () => import("../views/app/operatingPermit/IndexPage.vue"),
        },
        redirect: "/permission/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/operatingPermit/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/operatingPermit/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/operatingPermit/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.setting[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      /**
       * ==============================================================================
       *  printer
       * ==============================================================================
       */
      {
        path: "/printer",
        name: "printer",
        components: {
          default: () => import("../views/app/printer/IndexPage.vue"),
        },
        redirect: "/printer/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/printer/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/printer/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/printer/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.setting[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      {
        path: "/gestion",
        name: "gestion",
        components: {
          default: () => import("../views/app/order/orderGestion.vue"),
        },
      },
      /**
       * ==============================================================================
       *  kitchen
       * ==============================================================================
       */
      {
        path: "/kitchen",
        name: "kitchen",
        components: {
          default: () => import("../views/app/kitchen/IndexPage.vue"),
        },
        redirect: "/kitchen/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/kitchen/orders.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.kitchen[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      /**
       * ==============================================================================
       *  configuración general
       * ==============================================================================
       */
      {
        path: "/setting",
        name: "setting",
        components: {
          default: () => import("../views/app/setting/IndexPage.vue"),
          // sideBar: () => import("../components/SideBar.vue"),
        },
        redirect: "/setting/new",
        children: [
          {
            path: "new",
            component: () => import("../views/app/setting/New.vue"),
          },
          {
            path: "app",
            component: () => import("../views/app/setting/AppMovil.vue"),
          },
          {
            path: "dataphone",
            component: () => import("../views/app/setting/Dataphone.vue"),
          },
          {
            path: "siigo",
            component: () => import("../views/app/setting/Siigo.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.setting[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      {
        path: "/category-order",
        name: "categoryOrder",
        components: {
          default: () => import("../views/app/categoryOrder/IndexPage.vue"),
        },
        redirect: "/category-order/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/categoryOrder/List.vue"),
          },
          {
            path: "new",
            component: () => import("../views/app/categoryOrder/New.vue"),
          },
          {
            path: ":id",
            component: () => import("../views/app/categoryOrder/Update.vue"),
          },
        ],
        beforeEnter: (to, from, next) => {
          const permision = JSON.parse(
            localStorage.getItem("employee")
          ).permissions;
          const valid = permision.some(
            (element) => element.value === permissions.setting[0]
          );
          if (valid) next();
          else next({ name: "home" });
        },
      },
      {
        path: "/history",
        name: "history",
        components: {
          default: () => import("../views/app/actionHistory/IndexPage.vue"),
        },
        redirect: "/history/list",
        children: [
          {
            path: "list",
            component: () => import("../views/app/actionHistory/List.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "*",
    component: () => import("../views/Error.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const employee = JSON.parse(localStorage.getItem("employee"));
  if (
    !employee &&
    to.name != "autentication" &&
    to.name != "menu" &&
    to.name != "businessMenu"
    // to.name != "restaurant" &&
    // to.name != "plato"
  ) {
    next({ name: "autentication" });
  }
  //valida que el empleado tenga permiso en el negocio
  if (
    to.name != "business" &&
    to.name != "autentication" &&
    to.name != "menu" &&
    to.name != "businessMenu"
    // to.name != "restaurant" &&
    // to.name != "plato"
  ) {
    const placeBusiness = JSON.parse(localStorage.getItem("placeBusiness"));
    //si aun no hay negocio en el localstore me lleva a la vista de negocio
    if (!placeBusiness) {
      next({ name: "business" });
    }
    //si no tengo permiso en el negocio no pasar de allí
    else if (!employee?.placeBusiness.some((v) => v === placeBusiness?._id)) {
      next({ name: "business" });
    }
  }
  if (to.query.token) {
    const employee = jwt.decode(to.query.token);
    localStorage.setItem("token", to.query.token);
    localStorage.setItem("employee", JSON.stringify(employee));
    if (to.query.business != "") {
      try {
        const placeList = await place.business();
        const placeAux = placeList.find((e) => e._id === to.query.business);
        const find = employee?.placeBusiness.find(
          (v) => v === to.query.business
        );
        if (find) {
          localStorage.setItem("placeBusiness", JSON.stringify(placeAux));
          next({
            name: "gestion",
            query: {
              place: to.query.place,
              customer: to.query.customer,
              booking: to.query.booking,
              status: to.query.status,
            },
          });
        } else {
          next({ name: "business" });
        }
      } catch (error) {
        next({ name: "autentication" });
      }
    }
  }
  //si no entra en ninguno de esos caso darle acceso a la vista
  next();
});

export default router;

// import Vue from "vue";
// import VueRouter from "vue-router";
// import jwt from "@/services/jwtService";
// import place from "@/services/placeBusiness";
// import permissions from "@/constants/permissions";
// Vue.use(VueRouter);

// const routes = [
//   {
//     path: "/auth/login",
//     name: "autentication",
//     component: () => import("../views/auth/Login.vue"),
//   },
//   {
//     path: "/auth/business",
//     name: "business",
//     components: {
//       default: () => import("../views/auth/Business.vue"),
//       appBar: () => import("../components/AppBar.vue"),
//     },
//   },
//   {
//     path: "/",
//     name: "home",
//     components: {
//       default: () => import("../views/Home.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//   },
//   {
//     path: "/menu/:id",
//     name: "menu",
//     components: {
//       default: () => import("../views/menu/Menu.vue"),
//     },
//   },
//   {
//     path: "/restaurant/:id",
//     name: "restaurant",
//     components: {
//       default: () => import("../views/menu/Menu.vue"),
//     },
//   },
//   {
//     path: "/plato/:id",
//     name: "plato",
//     components: {
//       default: () => import("../views/menu/Menu.vue"),
//     },
//   },

//   /**
//    * ==============================================================================
//    *  MIX
//    * ==============================================================================
//    */
//   {
//     path: "/mix",
//     name: "mix",
//     components: {
//       default: () => import("../views/mix/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/mix/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/mix/List.vue"),
//       },
//       {
//         path: "new",
//         component: () => import("../views/mix/New.vue"),
//       },
//       {
//         path: ":id",
//         component: () => import("../views/mix/Update.vue"),
//       },
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.mix[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },

//   /**
//    * ==============================================================================
//    *  categories
//    * ==============================================================================
//    */
//   {
//     path: "/tag",
//     name: "tag",
//     components: {
//       default: () => import("../views/category/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/tag/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/category/List.vue"),
//       },
//       {
//         path: "new",
//         component: () => import("../views/category/New.vue"),
//       },
//       {
//         path: ":id",
//         component: () => import("../views/category/Update.vue"),
//       },
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.tag[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },
//   /**
//    * ==============================================================================
//    *  clasification
//    * ==============================================================================
//    */
//   {
//     path: "/category",
//     name: "category",
//     components: {
//       default: () => import("../views/clasification/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/category/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/clasification/List.vue"),
//       },
//       {
//         path: "new",
//         component: () => import("../views/clasification/New.vue"),
//       },
//       {
//         path: ":id",
//         component: () => import("../views/clasification/Update.vue"),
//       },
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.category[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },

//   /**
//    * ==============================================================================
//    *  mesa
//    * ==============================================================================
//    */
//   {
//     path: "/table",
//     name: "table",
//     components: {
//       default: () => import("../views/table/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/table/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/table/List.vue"),
//       },
//       {
//         path: "new",
//         component: () => import("../views/table/New.vue"),
//       },
//       {
//         path: ":id",
//         component: () => import("../views/table/Update.vue"),
//       },
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.setting[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },

//   /**
//    * ==============================================================================
//    *  orden
//    * ==============================================================================
//    */
//   {
//     path: "/order",
//     name: "order",
//     components: {
//       default: () => import("../views/order/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/order/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/order/List.vue"),
//       },
//       {
//         path: "list/closed",
//         component: () => import("../views/order/ClosedList.vue"),
//       },
//       // {
//       //   path: 'new',
//       //   component: () => import('../views/table/New.vue')
//       // },
//       // {
//       //   path: ':id',
//       //   component: () => import('../views/table/Update.vue')
//       // }
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.order[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },
//   /**
//    * ==============================================================================
//    *  pay
//    * ==============================================================================
//    */
//   {
//     path: "/pay",
//     name: "pay",
//     components: {
//       default: () => import("../views/pay/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/pay/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/pay/List.vue"),
//       },
//       {
//         path: "new",
//         component: () => import("../views/pay/New.vue"),
//       },
//       {
//         path: ":id",
//         component: () => import("../views/pay/Update.vue"),
//       },
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.setting[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },
//   /**
//    * ==============================================================================
//    *  pay
//    * ==============================================================================
//    */
//   {
//     path: "/permission",
//     name: "permission",
//     components: {
//       default: () => import("../views/operatingPermit/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/permission/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/operatingPermit/List.vue"),
//       },
//       {
//         path: "new",
//         component: () => import("../views/operatingPermit/New.vue"),
//       },
//       {
//         path: ":id",
//         component: () => import("../views/operatingPermit/Update.vue"),
//       },
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.setting[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },
//   /**
//    * ==============================================================================
//    *  printer
//    * ==============================================================================
//    */
//   {
//     path: "/printer",
//     name: "printer",
//     components: {
//       default: () => import("../views/printer/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/printer/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/printer/List.vue"),
//       },
//       {
//         path: "new",
//         component: () => import("../views/printer/New.vue"),
//       },
//       {
//         path: ":id",
//         component: () => import("../views/printer/Update.vue"),
//       },
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.setting[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },

//   /**
//    * ==============================================================================
//    *  kitchen
//    * ==============================================================================
//    */
//   {
//     path: "/kitchen",
//     name: "kitchen",
//     components: {
//       default: () => import("../views/kitchen/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/kitchen/list",
//     children: [
//       {
//         path: "list",
//         component: () => import("../views/kitchen/orders.vue"),
//       }
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.kitchen[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },

//   /**
//    * ==============================================================================
//    *  configuración general
//    * ==============================================================================
//    */
//   {
//     path: "/setting",
//     name: "setting",
//     components: {
//       default: () => import("../views/setting/IndexPage.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//     redirect: "/setting/new",
//     children: [
//       {
//         path: "new",
//         component: () => import("../views/setting/New.vue"),
//       }
//     ],
//     beforeEnter: (to, from, next) => {
//       const permision = JSON.parse(
//         localStorage.getItem("employee")
//       ).permissions;
//       const valid = permision.some(
//         (element) => element.value === permissions.setting[0]
//       );
//       if (valid) next();
//       else next({ name: "home" });
//     },
//   },

//   {
//     path: "/gestion",
//     name: "gestion",
//     components: {
//       default: () => import("../views/order/orderGestion.vue"),
//       sideBar: () => import("../components/SideBar.vue"),
//     },
//   },
//   {
//     path: "*",
//     component: () => import("../views/Error.vue"),
//   },
// ];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

// router.beforeEach(async (to, from, next) => {
//   const employee = JSON.parse(localStorage.getItem("employee"));
//   if (!employee && to.name != "autentication" && to.name != "menu" && to.name != "restaurant" && to.name != "plato") {
//     next({ name: "autentication" });
//   }
//   //valida que el empleado tenga permiso en el negocio
//   if (
//     to.name != "business" &&
//     to.name != "autentication" &&
//     to.name != "menu" &&
//     to.name != "restaurant" &&
//     to.name != "plato"
//   ) {
//     const placeBusiness = JSON.parse(localStorage.getItem("placeBusiness"));
//     //si aun no hay negocio en el localstore me lleva a la vista de negocio
//     if (!placeBusiness) {
//       next({ name: "business" });
//     }
//     //si no tengo permiso en el negocio no pasar de allí
//     else if (!employee?.placeBusiness.some((v) => v === placeBusiness?._id)) {
//       next({ name: "business" });
//     }
//   }
//   if (to.query.token) {
//     const employee = jwt.decode(to.query.token);
//     localStorage.setItem("token", to.query.token);
//     localStorage.setItem("employee", JSON.stringify(employee));
//     if (to.query.business != "") {
//       try {
//         const placeList = await place.business();
//         const placeAux = placeList.find((e) => e._id === to.query.business);
//         const find = employee?.placeBusiness.find(
//           (v) => v === to.query.business
//         );
//         if (find) {
//           localStorage.setItem("placeBusiness", JSON.stringify(placeAux));
//           next({
//             name: "gestion",
//             query: {
//               place: to.query.place,
//               customer: to.query.customer,
//               booking: to.query.booking,
//               status: to.query.status,
//             },
//           });
//         } else {
//           next({ name: "business" });
//         }
//       } catch (error) {
//         next({ name: "autentication" });
//       }
//     }
//   }
//   //si no entra en ninguno de esos caso darle acceso a la vista
//   next();
// });

// export default router;
